import React from 'react';

import { Combobox } from '@headlessui/react';
import { SearchIcon, CourseIcon, PlaceSolidIcon } from '@lightspeed/golf-b2c-design-system';
import { useT } from '@transifex/react';

type Location = {
  name: string;
  type: 'location';
  uuid: string;
};

type Club = {
  formattedLocation: string;
  name: string;
  slug: string;
  type: 'club';
  uuid: string;
};

type SearchQuery = {
  query: string;
  type: 'query';
};

type ResultsItem = Club | Location;
type SelectedItem = Club | Location | SearchQuery;

type ResultSection = {
  name: string;
  children: ResultsItem[];
};

type ClubsAndLocationsComboboxProps = {
  results: ResultSection[];
  query?: string;
  initialQuery?: string;
  onQueryChange(query: string): void;
  onSelect(selected: ResultsItem): void;
  selected: SelectedItem | null;
  offCanvas?: boolean;
  name?: string;
};

export const ClubsAndLocationsCombobox = ({
  query,
  initialQuery,
  onQueryChange,
  onSelect,
  results,
  selected,
  offCanvas,
  name,
}: ClubsAndLocationsComboboxProps) => {
  const t = useT();

  return (
    <Combobox defaultValue={selected} onChange={onSelect} nullable name={name}>
      <div className="relative w-full">
        <div className="focus-visible:ring-offset-teal-300 relative flex w-full flex-row items-stretch overflow-hidden rounded-md border border-grey-300 bg-white focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2">
          <div className="flex grow-0 items-center justify-center px-3">
            <SearchIcon aria-hidden="true" className="h-5 w-5" />
          </div>
          <Combobox.Button
            as="div"
            className="text-inherit w-full grow cursor-pointer border-none bg-none outline-none"
            onClick={(e) => {
              if (!query) {
                e.preventDefault();
              }
            }}
          >
            <Combobox.Input
              className="w-full grow appearance-none px-3 py-4 outline-none placeholder:text-grey-600"
              displayValue={(value: ResultsItem) => value?.name || query || initialQuery || ''}
              placeholder={t('Search by course or location')}
              aria-label={t('Search by course or location')}
              onChange={(event) => onQueryChange(event.target.value)}
            />
          </Combobox.Button>
        </div>
        <Combobox.Options
          className={`max-h-60 absolute z-50 mt-1 w-full overflow-auto py-2 empty:hidden focus:outline-none ${
            !offCanvas
              ? 'rounded-sm border border-grey-100 bg-white shadow-3 ring-1 ring-black ring-opacity-5'
              : ''
          }`}
        >
          {/* Allow search term to be selected (Headless UI and search submit: https://github.com/tailwindlabs/headlessui/discussions/1228) */}
          {query !== '' && (
            <Combobox.Option value={{ query, type: 'query' }} className="hidden">
              {query}
            </Combobox.Option>
          )}
          {results.length === 0 && query !== '' ? (
            <div className="relative cursor-default select-none px-4 py-2 text-grey-700">
              {t('Nothing found.')}
            </div>
          ) : (
            <>
              {results.map((section, index) => (
                <React.Fragment key={section.name}>
                  {index === 1 && section.children.length > 0 && (
                    <div className="mx-2 my-4 border-t border-grey-200 md:my-2" />
                  )}
                  {section.children.map((item) => (
                    <Combobox.Option
                      key={item.uuid}
                      className={({ active, selected }) =>
                        `relative flex cursor-pointer items-center space-x-3 px-6 py-2 text-base text-black hover:bg-grey-100 focus:outline-none lg:px-4 ${
                          active ? 'bg-grey-100' : ''
                        } ${selected ? 'font-bold' : ''}`
                      }
                      value={item}
                    >
                      {item.type === 'club' ? (
                        <div className="flex items-center space-x-4">
                          <CourseIcon />
                          <div className="flex flex-col">
                            {item.name}
                            <span className="text-sm text-grey-600">{item.formattedLocation}</span>
                          </div>
                        </div>
                      ) : (
                        <div className="flex items-center space-x-4">
                          <PlaceSolidIcon />
                          <span>{item.name}</span>
                        </div>
                      )}
                    </Combobox.Option>
                  ))}
                </React.Fragment>
              ))}
            </>
          )}
        </Combobox.Options>
      </div>
    </Combobox>
  );
};
