export type Destination = {
  state?: string;
  country?: string;
  name: string;
  photo?: string;
  slug: string;
};

const destinationsList = {
  unitedStates: [
    {
      name: 'Alabama',
      country: 'United States',
      slug: '/locations/alabama',
    },
    {
      name: 'Alaska',
      country: 'United States',
      slug: '/locations/alaska',
    },
    {
      name: 'Arizona',
      country: 'United States',
      slug: '/locations/arizona',
      photo: 'https://chronogolf.s3.amazonaws.com/assets/images/app/arizona.jpg',
    },
    {
      name: 'Arkansas',
      country: 'United States',
      slug: '/locations/arkansas',
    },
    {
      name: 'California',
      country: 'United States',
      slug: '/locations/california',
      photo: 'https://chronogolf.s3.amazonaws.com/assets/images/app/california.jpg',
    },
    {
      name: 'Colorado',
      country: 'United States',
      slug: '/locations/colorado',
    },
    {
      name: 'Connecticut',
      country: 'United States',
      slug: '/locations/connecticut',
    },
    {
      name: 'Delaware',
      country: 'United States',
      slug: '/locations/delaware',
    },
    {
      name: 'District of Columbia',
      country: 'United States',
      slug: '/locations/district-of-columbia',
    },
    {
      name: 'Florida',
      country: 'United States',
      slug: '/locations/florida',
      photo: 'https://chronogolf.s3.amazonaws.com/assets/images/app/florida.jpg',
    },
    {
      name: 'Georgia',
      country: 'United States',
      slug: '/locations/georgia',
    },
    {
      name: 'Hawaii',
      country: 'United States',
      slug: '/locations/hawaii',
      photo: 'https://chronogolf.s3.amazonaws.com/assets/images/app/hawaii.jpg',
    },
    {
      name: 'Idaho',
      country: 'United States',
      slug: '/locations/idaho',
    },
    {
      name: 'Illinois',
      country: 'United States',
      slug: '/locations/illinois',
      photo: 'https://chronogolf.s3.amazonaws.com/assets/images/app/illinois.jpg',
    },
    {
      name: 'Indiana',
      country: 'United States',
      slug: '/locations/indiana',
    },
    {
      name: 'Iowa',
      country: 'United States',
      slug: '/locations/iowa',
    },
    {
      name: 'Kansas',
      country: 'United States',
      slug: '/locations/kansas',
    },
    {
      name: 'Kentucky',
      country: 'United States',
      slug: '/locations/kentucky',
    },
    {
      name: 'Louisiana',
      country: 'United States',
      slug: '/locations/louisiana',
    },
    {
      name: 'Maine',
      country: 'United States',
      slug: '/locations/maine',
    },
    {
      name: 'Maryland',
      country: 'United States',
      slug: '/locations/maryland',
    },
    {
      name: 'Massachusetts',
      country: 'United States',
      slug: '/locations/massachusetts',
    },
    {
      name: 'Michigan',
      country: 'United States',
      slug: '/locations/michigan',
    },
    {
      name: 'Minnesota',
      country: 'United States',
      slug: '/locations/minnesota',
    },
    {
      name: 'Mississippi',
      country: 'United States',
      slug: '/locations/mississippi',
    },
    {
      name: 'Missouri',
      country: 'United States',
      slug: '/locations/missouri',
    },
    {
      name: 'Montana',
      country: 'United States',
      slug: '/locations/montana',
    },
    {
      name: 'Nebraska',
      country: 'United States',
      slug: '/locations/nebraska',
    },
    {
      name: 'Nevada',
      country: 'United States',
      slug: '/locations/nevada',
    },
    {
      name: 'New Hampshire',
      country: 'United States',
      slug: '/locations/new-hampshire',
    },
    {
      name: 'New Jersey',
      country: 'United States',
      slug: '/locations/new-jersey',
    },
    {
      name: 'New Mexico',
      country: 'United States',
      slug: '/locations/new-mexico',
    },
    {
      name: 'New York',
      country: 'United States',
      slug: '/locations/new-york',
    },
    {
      name: 'North Carolina',
      country: 'United States',
      slug: '/locations/north-carolina',
    },
    {
      name: 'North Dakota',
      country: 'United States',
      slug: '/locations/north-dakota',
    },
    {
      name: 'Ohio',
      country: 'United States',
      slug: '/locations/ohio',
    },
    {
      name: 'Oklahoma',
      country: 'United States',
      slug: '/locations/oklahoma',
    },
    {
      name: 'Oregon',
      country: 'United States',
      slug: '/locations/oregon',
    },
    {
      name: 'Pennsylvania',
      country: 'United States',
      slug: '/locations/pennsylvania',
    },
    {
      name: 'Rhode Island',
      country: 'United States',
      slug: '/locations/rhode-island',
    },
    {
      name: 'South Carolina',
      country: 'United States',
      slug: '/locations/south-carolina',
      photo: 'https://chronogolf.s3.amazonaws.com/assets/images/app/south-carolina.jpg',
    },
    {
      name: 'South Dakota',
      country: 'United States',
      slug: '/locations/south-dakota',
    },
    {
      name: 'Tennessee',
      country: 'United States',
      slug: '/locations/tennessee',
    },
    {
      name: 'Texas',
      country: 'United States',
      slug: '/locations/texas',
    },
    {
      name: 'Utah',
      country: 'United States',
      slug: '/locations/utah',
    },
    {
      name: 'Vermont',
      country: 'United States',
      slug: '/locations/vermont',
    },
    {
      name: 'Virginia',
      country: 'United States',
      slug: '/locations/virginia',
    },
    {
      name: 'Washington',
      country: 'United States',
      slug: '/locations/washington',
    },
    {
      name: 'West Virginia',
      country: 'United States',
      slug: '/locations/west-virginia',
    },
    {
      name: 'Wisconsin',
      country: 'United States',
      slug: '/locations/wisconsin',
    },
    {
      name: 'Wyoming',
      country: 'United States',
      slug: '/locations/wyoming',
    },
  ],
  canada: [
    {
      name: 'Alberta',
      country: 'Canada',
      slug: '/locations/alberta',
      photo: 'https://chronogolf.s3.amazonaws.com/assets/images/app/alberta.jpg',
    },
    {
      name: 'British Columbia',
      country: 'Canada',
      slug: '/locations/british-columbia',
      photo: 'https://chronogolf.s3.amazonaws.com/assets/images/app/british-columbia.jpg',
    },
    { name: 'Manitoba', country: 'Canada', slug: '/locations/manitoba' },
    { name: 'New Brunswick', country: 'Canada', slug: '/locations/new-brunswick' },
    {
      name: 'Newfoundland and Labrador',
      country: 'Canada',
      slug: '/locations/newfoundland-and-labrador',
    },
    { name: 'Northwest Territories', country: 'Canada', slug: '/locations/northwest-territories' },
    { name: 'Nova Scotia', country: 'Canada', slug: '/locations/nova-scotia' },
    {
      name: 'Ontario',
      country: 'Canada',
      slug: '/locations/ontario',
      photo: 'https://chronogolf.s3.amazonaws.com/assets/images/app/ontario.jpg',
    },
    { name: 'Prince Edward Island', country: 'Canada', slug: '/locations/prince-edward-island' },
    {
      name: 'Quebec',
      country: 'Canada',
      slug: '/locations/quebec',
      photo: 'https://chronogolf.s3.amazonaws.com/assets/images/app/quebec.jpg',
    },
    { name: 'Saskatchewan', country: 'Canada', slug: '/locations/saskatchewan' },
    { name: 'Yukon', country: 'Canada', slug: '/locations/yukon' },
  ],
  france: [
    { name: 'Alsace', country: 'France', slug: '/locations/alsace' },
    { name: 'Aquitaine', country: 'France', slug: '/locations/aquitaine' },
    { name: 'Auvergne', country: 'France', slug: '/locations/auvergne' },
    { name: 'Basse-Normandie', country: 'France', slug: '/locations/basse-normandie' },
    { name: 'Bourgogne', country: 'France', slug: '/locations/bourgogne' },
    { name: 'Bretagne', country: 'France', slug: '/locations/bretagne' },
    { name: 'Centre', country: 'France', slug: '/locations/centre' },
    { name: 'Champagne-Ardenne', country: 'France', slug: '/locations/champagne-ardenne' },
    { name: 'Franche-Comté', country: 'France', slug: '/locations/franche-comte' },
    { name: 'Haute-Normandie', country: 'France', slug: '/locations/haute-normandie' },
    { name: 'Île-de-France', country: 'France', slug: '/locations/ile-de-france' },
    { name: 'Languedoc-Roussillon', country: 'France', slug: '/locations/languedoc-roussillon' },
    { name: 'Limousin', country: 'France', slug: '/locations/limousin' },
    { name: 'Lorraine', country: 'France', slug: '/locations/lorraine' },
    { name: 'Midi-Pyrénées', country: 'France', slug: '/locations/midi-pyrenees' },
    { name: 'Nord-Pas-de-Calais', country: 'France', slug: '/locations/nord-pas-de-calais' },
    { name: 'Pays de la Loire', country: 'France', slug: '/locations/pays-de-la-loire' },
    { name: 'Picardie', country: 'France', slug: '/locations/picardie' },
    { name: 'Poitou-Charentes', country: 'France', slug: '/locations/poitou-charentes' },
    {
      name: "Provence-Alpes-Côte d'Azur",
      country: 'France',
      slug: '/locations/provence-alpes-cote-d-azur',
    },
    { name: 'Rhône-Alpes', country: 'France', slug: '/locations/rhone-alpes' },
  ],
};

const usaDestinations = destinationsList.unitedStates;
const canadaDestinations = destinationsList.canada;
const franceDestinations = destinationsList.france;

export const getTrendingDestination = (list: string[]): Destination[] => {
  const trendingUsa = usaDestinations.filter((d) => list.includes(d.name));
  const trendingCanada = canadaDestinations.filter((d) => list.includes(d.name));
  const trendingFrance = franceDestinations.filter((d) => list.includes(d.name));

  return [...trendingUsa, ...trendingCanada, ...trendingFrance].sort(
    (a, b) => list.indexOf(a.name) - list.indexOf(b.name)
  );
};

export { usaDestinations, canadaDestinations, franceDestinations };
