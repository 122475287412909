import { Destination } from 'src/api/destination';
import { DestinationCard } from 'src/components/DestinationCard';

type TrendingDestinationsProps = {
  destinations: Destination[];
};

export const TrendingDestinations = ({ destinations }: TrendingDestinationsProps) => {
  return (
    <div className="grid grid-cols-2 gap-x-4 gap-y-8 md:grid-cols-2 md:gap-x-6 lg:grid-cols-4">
      {destinations.map((destination) => (
        <DestinationCard key={destination.name} destination={destination} />
      ))}
    </div>
  );
};
