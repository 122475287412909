import { tokens } from '@lightspeed/golf-b2c-design-system';
import { useT } from '@transifex/react';
import Image from 'next/image';

import { Destination } from 'src/api/destination';
import { base64BlurPreload } from 'src/utils/images';

type DestinationCardProps = {
  destination: Destination;
};

export const DestinationCard = ({ destination }: DestinationCardProps) => {
  const t = useT();
  return (
    <a href={`${process.env.MONOLITH_BASE_URL}${destination.slug}`} className="block">
      <div className="relative mb-2 block aspect-square w-full overflow-hidden md:aspect-16/9">
        {destination.photo ? (
          <Image
            quality={75}
            className="object-cover"
            src={destination.photo}
            alt={t('{destinationName} picture', { destinationName: destination.name })}
            fill
            sizes={`
          (max-width: ${tokens.breakpoints.md}) 100vw,
          (max-width: ${tokens.breakpoints.lg}) 50vw,
          (max-width: ${tokens.breakpoints.xl}) 33vw,
          25vw
          `}
            placeholder="blur"
            role="presentation"
            blurDataURL={base64BlurPreload}
          />
        ) : null}
      </div>
      <h3 className="mb-1 text-lg font-bold">{destination.name}</h3>
      <div className="text-sm uppercase text-grey-600">
        {destination.state ? `${destination.state}, ` : null}
        {destination.country}
      </div>
    </a>
  );
};
