import React, { useState, SyntheticEvent, useEffect } from 'react';

import { Button } from '@lightspeed/golf-b2c-design-system';
import { t } from '@transifex/native';
import axios, { AxiosResponse } from 'axios';

import { trackGa4Event } from 'src/utils/analytics';
import { reportError } from 'src/utils/reporting';
import { urlEncode } from 'src/utils/urls';

import { ClubsAndLocationsCombobox } from './ClubsAndLocationsCombobox';

enum AnalyticsEvents {
  SEARCH_CLUB = 'SearchClub',
  SEARCH_LOCATION = 'SearchLocation',
  SEARCH_TERM = 'SearchTerm',
}

type Location = {
  name: string;
  type: 'location';
  uuid: string;
};

type Club = {
  formattedLocation: string;
  name: string;
  slug: string;
  type: 'club';
  uuid: string;
};

type SearchQuery = {
  query: string;
  type: 'query';
};

type ResultsItem = Club | Location;
type SelectedItem = Club | Location | SearchQuery;

type ResultSection = {
  name: string;
  children: ResultsItem[];
};

const buildLocationsSection = (locations: Location[]) => {
  return {
    name: 'Locations',
    children: locations,
  };
};

const buildClubsSection = (clubs: Club[]) => {
  return {
    name: 'Clubs',
    children: clubs,
  };
};

const fetchClubs = (query: string): Promise<Club[]> => {
  return axios
    .get(`${process.env.MONOLITH_BASE_URL}/marketplace/v2/search/clubs?name=${query}`)
    .then(({ data }: AxiosResponse<Club[]>) => {
      return data.map((club: Club) => ({
        uuid: club.uuid,
        name: club.name,
        type: 'club',
        formattedLocation: club.formattedLocation,
        slug: club.slug,
      }));
    });
};

const fetchLocations = (query: string): Promise<Location[]> => {
  if (!query) Promise.reject(new Error('Need valid text input'));
  if (typeof window === 'undefined') Promise.reject(new Error('Need valid window object'));
  if (typeof window.google === 'undefined') Promise.reject(new Error('Google Map SDK not loaded'));

  return new window.google.maps.places.AutocompleteService()
    .getPlacePredictions({
      input: query,
      types: ['(regions)'],
    })
    .then(({ predictions }: { predictions: google.maps.places.AutocompletePrediction[] }) => {
      return predictions.map((prediction: google.maps.places.AutocompletePrediction) => ({
        uuid: prediction.place_id,
        name: prediction.description,
        type: 'location',
      }));
    });
};

type ClubsAndLocationsSearchProps = {
  wrapperClassName?: string;
  offCanvas?: boolean;
  initialQuery?: string;
  showButton?: boolean;
};

export const ClubsAndLocationsSearch = ({
  wrapperClassName,
  offCanvas,
  initialQuery,
  showButton,
}: ClubsAndLocationsSearchProps) => {
  const [results, setResults] = useState<ResultSection[]>([]);
  const [query, setQuery] = useState<string | undefined>();
  const [selected, setSelected] = useState<SelectedItem | null>(null);

  useEffect(() => {
    const getResults = setTimeout(() => {
      if (query) {
        fetchResults(query);
      }
    }, 150);
    return () => clearTimeout(getResults);
  }, [query]);

  const fetchResults = async (query: string): Promise<void> => {
    let locations: Location[] = [];
    let clubs: Club[] = [];

    const [locationResults, clubResults] = await Promise.allSettled([
      fetchLocations(query),
      fetchClubs(query),
    ]);

    if (locationResults.status === 'fulfilled') {
      locations = locationResults.value;
    } else if (locationResults.reason.code !== 'ERR_CANCELED') {
      reportError(locationResults.reason);
    }

    if (clubResults.status === 'fulfilled') {
      clubs = clubResults.value;
    } else if (clubResults.reason.code !== 'ERR_CANCELED') {
      reportError(clubResults.reason);
    }

    const items = [];
    if (locations.length) {
      items.push(buildLocationsSection(locations));
    }
    if (clubs.length) {
      items.push(buildClubsSection(clubs));
    }

    setResults(items);
  };

  useEffect(() => {
    onSelectionChange();
  }, [selected]);

  const onSelectionChange = () => {
    if (selected) {
      // Submit the form with the search query: https://github.com/tailwindlabs/headlessui/discussions/1228
      if (isSearchQuery(selected) && query) {
        onSubmit();
      }
      if (isClub(selected)) {
        trackGa4Event(AnalyticsEvents.SEARCH_CLUB, {
          searchTerm: selected.name,
        });
        redirectTo(`${process.env.NEXT_BASE_URL}/club/${selected.slug}/`);
      }
      if (isLocation(selected)) {
        trackGa4Event(AnalyticsEvents.SEARCH_LOCATION, {
          searchTerm: selected.name,
        });
        redirectTo(`${process.env.NEXT_BASE_URL}/clubs/${urlEncode(selected.name)}/`);
      }
    }
  };

  const isSearchQuery = (value: SelectedItem): value is SearchQuery => value.type === 'query';
  const isClub = (value: SelectedItem): value is Club => value.type === 'club';
  const isLocation = (value: SelectedItem): value is Location => value.type === 'location';

  const onSubmit = () => {
    if (query) {
      trackGa4Event(AnalyticsEvents.SEARCH_TERM, {
        searchTerm: query,
      });
      redirectTo(`${process.env.NEXT_BASE_URL}/clubs/${urlEncode(query)}/`);
    } else if (initialQuery) {
      trackGa4Event(AnalyticsEvents.SEARCH_TERM, {
        searchTerm: initialQuery,
      });
      redirectTo(`${process.env.NEXT_BASE_URL}/clubs/${urlEncode(initialQuery)}/`);
    } else {
      trackGa4Event(AnalyticsEvents.SEARCH_TERM, {
        searchTerm: 'noquery',
      });
      redirectTo(`${process.env.NEXT_BASE_URL}/clubs/`);
    }
  };

  const redirectTo = (url: string) => {
    window.location.href = url;
  };

  return (
    <form
      onSubmit={(e: SyntheticEvent) => {
        // Native form still submit if we put preventDefault in onSubmit
        // Aria buttons don't expose native events
        // So we keep it here to solve that issue and make the button binding easier
        e.preventDefault();
        onSubmit();
      }}
      className={`${wrapperClassName} relative inline-flex w-full flex-col text-black`}
    >
      <div className="flex items-stretch gap-2">
        <ClubsAndLocationsCombobox
          query={query}
          initialQuery={initialQuery}
          onQueryChange={setQuery}
          onSelect={setSelected}
          results={results}
          selected={selected}
          offCanvas={offCanvas}
          name="club-location-search"
        />
        {showButton && (
          <>
            {/* Button's height needs to be slightly increase to match input heights */}
            <div className="hidden items-stretch lg:flex">
              <Button type="button" onPress={onSubmit} intent="primary">
                {t('Search')}
              </Button>
            </div>
          </>
        )}
      </div>
    </form>
  );
};
